import React from 'react'


function Footer(){
        return (
            <footer>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    All rights reserved ©  <a rel="nofollow" href="https://www.facebook.com/xyphersolutionsph" className="tm-text-link" target="_parent">Xypher Solutions Inc.</a></p>
                  {/* You shall support us a little via PayPal to info@templatemo.com */}
                </div>
              </div>
            </div>
          </footer>
        )
    }

export default Footer
