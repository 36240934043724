import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';


import Navbar from './components/Navbar/Navbar.js';
import Home from './components/Home.js';
import About from './components/About.js';
import Service from './components/Service.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';

import "../src/assets/css/style.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/font-awesome.css";

import "../src/assets/js/accordions.js";
import "../src/assets/js/bootstrap.min.js";
import "../src/assets/js/custom.js";
import "../src/assets/js/imgfix.min.js";
import "../src/assets/js/jquery-2.1.0.min.js";
import "../src/assets/js/jquery.counterup.min.js";
import "../src/assets/js/mixitup.js";
import "../src/assets/js/popper.js";
import "../src/assets/js/scrollreveal.min.js";
import "../src/assets/js/waypoints.min.js";

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <Navbar />
    <Home />
    <About />
    <Service />
    <Contact />
    <Footer />
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
