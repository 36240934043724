import React from 'react'


function Contact(){
        return (
            <section className="section" id="contact-us">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div id="map">
                    <iframe title= " " src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7730.595593927672!2d120.97725!3d14.352170000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4ba6a6dcd743811a!2sMark%20IV%20BUILDING!5e0!3m2!1sen!2sus!4v1606376377880!5m2!1sen!2sus" style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} width="100%" height={413} frameBorder={0} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div className="contact-form">
                    <div id="contact">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <p>Connect with us</p>
                          <ul className="social-icons">
                            <li> <a href="https://www.facebook.com/xyphersolutionsph"><i className="fa fa-phone" />(046) 456 5557</a></li>
                            <li><a href="https://www.google.com/maps/place/Mark+IV+BUILDING/@14.351068,120.976284,6112m/data=!3m1!1e3!4m5!3m4!1s0x0:0x4ba6a6dcd743811a!8m2!3d14.3521698!4d120.9772497?hl=en"><i className="fa fa-map-marker" />Dasmariñas, Cavite</a></li>
                            <li><a href="https://www.facebook.com/xyphersolutionsph"><i className="fa fa-facebook" />@xyphersolutionsph</a></li>
                            <li><a href="https://www.linkedin.com/company/xypher-solutions/"><i className="fa fa-linkedin" />Xypher Solutions</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
    }

export default Contact