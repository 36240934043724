export const MenuItems = [
    {
        title: 'Home',
        url: '#top',
        cName: 'nav-links'
    },
    {
        title: 'About',
        url: '#features',
        cName: 'nav-links'
    },
    {
        title: 'Service',
        url: '#trainers',
        cName: 'nav-links'
    },
    {
        title: 'Contact',
        url: '#contact-us',
        cName: 'nav-links'
    }

]