import React from 'react'

import mission from "../assets/images/mission.svg";
import vision from "../assets/images/vision.svg";
import commitment from "../assets/images/commitment.svg";
import creativity from "../assets/images/creativity.svg";
import innovation from "../assets/images/innovation.svg";


function About(){
        return (
<section className="section" id="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-12">
              <div className="section-heading">
                <h2>Our <em>Company</em></h2>                       
                <p>Xypher IT Solutions Inc. is an emerging software company from the Philippines that offers advancements in operating by system by providing innovative software solutions since February 2019. We take businesses with manual operating system into new horizon through developing cutting-edge systems suited to their needs, enabling them to work more efficiently and contribute to each of our clients’ sucess in the long run.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <ul className="features-items">
                <li className="feature-item">
                  <div className="left-icon">
                  <img src={mission} alt="Mission" />
                  </div>
                  <div className="right-content">
                    <h4>Mission</h4>
                    <p className="aboutdesc">Xypher IT Solutions Inc. creates innovative solutions to improve our clients’ business systems and processes. We provide a more secure network flow of information and environment for confidential and sensitive data paving the way to automated ecosystems that pushes the limits off current technological capabilities and continue advancing in the field of information technology.</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="features-items">
                <li className="feature-item">
                  <div className="left-icon">
                  <img src={vision} alt="Vision" />
                  </div>
                  <div className="right-content">
                    <h4>Vision</h4>
                    <p>Xypher IT Solutions Inc. is the leader in creating advanced IT solutions software that automates business process and manual systems while reducing the carbon footprint of our clients thus promoting more sustainable business processes taking care of the present while ensuring a progresive future.</p>
                  </div>
                </li>                    
              </ul>
            </div>
          </div>
        </div>
        <div className="core-values">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-container">
                  <h2>CORE <em>VALUES</em> </h2>
                </div>
              </div>
              <div className="col-lg-4">
             
                <div className="card">
                  <div className="card-body col">
                  <img src={commitment} alt="Commitment" />
                    <h4 className="card-title">Commitment</h4>
                  </div>
                </div>
            
              </div>
             
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                  <img src={creativity} alt="Creativity" />
                    <h4 className="card-title">Creativity</h4>
                  </div>
                </div>
          
              </div>
            
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                  <img src={innovation} alt="Innovation" />
                    <h4 className="card-title">Innovation</h4>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }


export default About
