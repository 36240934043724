import React from 'react'


import WebDev from "../assets/images/WebDev.jpg";
import WebApp from "../assets/images/WebApp.jpg";
import BusinessEmail from "../assets/images/BusinessEmail.jpg";

function Service(){
        return (
            <section className="section" id="trainers">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <div className="section-heading">
                    <h2>Our <em>Services</em></h2>
                    <p>We have three services that may help your business</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                    <img src={WebDev} alt="WebDev" />
                    </div>
                    <div className="down-content">
                      <span>Web Development</span>
                      <p>We are capable in website development services providing unconventional web ideas and web content development. Our website development system is made to upgrade and enhance a company’s website to achieve a better market in line with the client’s goal. We offer user-friendly application with modern and advance database system that is beneficial to the company’s growth and success.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                    <img src={WebApp} alt="WebApp" />
                    </div>
                    <div className="down-content">
                      <span>Web-based Application</span>
                      <p>Our expertise includes creating exemplary web based application system for an easier customer interaction system through a web browser. We provide a user-friendly and strong interface that can be easily maneuvered by the clients, giving the the access to converse with their customer smoothly, test the functionality and monitor the process of their business online without difficulty.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="trainer-item">
                    <div className="image-thumb">
                    <img src={BusinessEmail} alt="Business Email" />
                    </div>
                    <div className="down-content">
                      <span>Business E-mail</span>
                      <p>Our team is also adept in generating business e-mails through Zoho maid, providing more convenience in the management of communication in the clients' business network. We always want the best for you that is why our client's requests and needs will always be at the top of our priorities. We will give you quality services and solutions to your business. We always want the best for you.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
    }

export default Service