import React from 'react'


import HeaderPic from "../assets/images/header.jpg";

function Home(){
        return (

            <div className="main-banner" id="top">
            <img className="header-bg" src={HeaderPic} alt="home" />
            <div className="caption">
              <h2>Xypher wants the best for your business</h2>
              <p>We give quality solutions that will surely meet your expectations.</p>
              <div className="main-button scroll-to-section">
                <a href="#contact-us">Contact Us</a>
              </div>
            </div>
          </div>
            
        )
    }

export default Home
